<script setup>
import {onUnmounted, onMounted, ref, computed} from "vue";

import { inject } from 'vue'
import analytics from "@/js/analytics";
import LanguageToggle from "@/components/LanguageToggle";
import audioControl from "@/js/audioControl";
import {useStore} from "vuex";
const translate = inject('translate')
const timeout = ref(null);

onUnmounted(() => clearTimeout(timeout.value));

const store = useStore();
const cookiesAccepted = computed(() => store.getters.getCookiesAccepted);
const locale = computed(() => store.getters.getLocale);

onMounted(() => {
  analytics.setPage("landing page");
});

const linkout = function (url, analyticsEvent) {
  audioControl.restartAudio("click");
  analytics.analyticsMethod(analyticsEvent);
  window.open(url, "_blank");
};

</script>

<template>
  <div class="landing-page" id = "scroll-element">
      <img class = "tendril-overlay" src="../../public/assets/imgs/tendril-overlay-2.png"/>
      <div class = "venom-text-body" v-html="translate('main-landing-instruction')"></div>

      <div class = "venom-button continue-button" @click="$emit('handle-continue')" ontouchstart>
        <div class = "text"  v-html="translate('lets-go-button')"></div>
        <img class = "tendril-over-button" src="../../public/assets/imgs/tendril-overlay-1.png"/>
      </div>

      <img class = "movie-image" :src="`./assets/imgs/venom-logo/${locale}.png`"/>
      <div class = "ticket-area">
        <div class = "venom-button ticket-button" @click="linkout(translate('tickets-link'), 'venomize_my_pet_get_tickets_now')" ontouchstart>
          <div class = "text" v-html="translate('get-tickets-landing-button')"></div>
        </div>
        <!--
        <div class = "venom-button ticket-button" @click="linkout(translate('official-site-link'), 'venomize_my_pet_visit_official_site')" ontouchstart>
          <div class = "text" v-html="translate('official-site-button')"></div>
        </div>
        -->
      </div>

      <div class = "trailer-area flex-column">
        <img class="trailer-text" :src="`./assets/imgs/trailer-text/trailer-${locale}.png`"/>

        <img v-if="!cookiesAccepted" onclick="window.consentHelper.showConsentDialog()" class = "trailer-video" src="../../public/assets/imgs/trailer-thumbnail.png"/>
        <iframe v-else class = "trailer-video" :src="translate('trailer-link')+'?enablejsapi=1'" title="VENOM: THE LAST DANCE – Official Trailer (HD)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
  </div>
</template>

<style scoped lang='scss'>
@import '../sass/GlobalClasses';
@import '../sass/Settings';

.landing-page {
  display: flex;
  align-items: center;
  flex-direction: column;

  .venom-text-body {
    position: relative;
    font-size: vw(52);
    margin-top: vw(20);
    width: vw(880);
  }



  .continue-button {
    position: relative;
    width: vw(620);
    border-radius: vw(130);
    height: vw(130);
    margin-top: vw(78);

    .text{
      font-size: vw(48);
      font-weight: bold;
    }
    transition: background-position 0.7s;
    background: #000000 linear-gradient(96deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 35%, rgba(255,255,255,0.25) 50%, rgba(0,0,0,1) 65%, rgba(0,0,0,1) 100%);
    background-repeat: no-repeat;
    animation: continue-shine 5s ease-in-out infinite;
  }
  .continue-button:hover {
    background: #220001 linear-gradient(96deg, rgba(34,0,0,1) 0%, rgba(34,0,0,1) 35%, rgba(255,255,255,0.25) 50%, rgba(34,0,0,1) 65%, rgba(34,0,0,1) 100%);
    background-repeat: no-repeat;
  }
  @keyframes continue-shine {
    0% {
      background-position: -35vw;
    }
    20% {
      background-position: 35vw;
    }
    100% {
      background-position: 35vw;
    }

  }

  .movie-image {
    position: relative;
    margin-top: vw(97);
    width: vw(604);
    left: vw(-10);
    height: auto;
  }
  .tendril-overlay {
    position: absolute;
    top: vw(860);
    width: 100%;
    height: auto;
  }
  .tendril-over-button {
    position: absolute;
    left: -1vw;
    top: 9vw;
    width: 38vw;
    height: auto;
    z-index: 2;
  }
  .ticket-area {
    position: relative;
    margin-top: vw(57);
    width: 100%;
    height: vw(99);

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;

    .ticket-button {
      position: relative;
      width: vw(440);
      border-radius: vw(100);
      height: vw(100);

      .text {
        font-size: vw(29);
        font-weight: bold;
      }
    }
  }

  .trailer-area {
    position: relative;
    margin-top: vw(78);
    width: 100%;
    height: vw(801);
    margin-bottom: vw(468);
    z-index: 1;

    .trailer-text {
      width: vw(300);
      height: auto;
    }

    .trailer-video {
      position: relative;
      width: vw(1024);
      height: vw(576);
      margin-top: vw(45);
      pointer-events: auto;
      border-radius: vw(33);
      border: solid vw(3) #999;
      overflow: hidden;
    }
  }
}
@media screen and (min-aspect-ratio: 1/1) {
  .landing-page {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: vw(500, $design-width-desktop);
    left: vw(109, $design-width-desktop);
    position: relative;
    top: vw(-260, $design-width-desktop);

    .venom-text-body {
      display: none;
    }
    .continue-button {
      position: relative;
      width: vw(420, $design-width-desktop);
      border-radius: vw(90, $design-width-desktop);
      height: vw(90, $design-width-desktop);
      margin-top: 0;

      .text{
        font-size: vw(30, $design-width-desktop);
      }
    }
    .tendril-overlay {
      display: none;
    }
    .tendril-over-button {
      top: 3.7vw;
      left: 4.5vw;
      width: 23.7083333333vw;
    }
    .movie-image {
      margin-top: vw(205, $design-width-desktop);
      width: vw(498, $design-width-desktop);
    }
    .ticket-area {
      position: relative;
      margin-top: vw(77, $design-width-desktop);
      width: auto;
      height: auto;

      flex-direction: column;

      .ticket-button {
        position: relative;
        width: vw(360, $design-width-desktop);
        border-radius: vw(80, $design-width-desktop);
        height: vw(80, $design-width-desktop);
        margin-bottom: vw(57, $design-width-desktop);

        .text {
          font-size: vw(22, $design-width-desktop);
        }
      }
    }
    .trailer-area {
      position: absolute;
      left: vw(678, $design-width-desktop);
      top: vw(140, $design-width-desktop);
      width: vw(1024, $design-width-desktop);
      display: flex;
      flex-direction: column;
      align-items: center;
      .trailer-text {
        width: vw(191, $design-width-desktop);
        height: auto;
      }
      .trailer-video {
        width: vw(1024, $design-width-desktop);
        height: vw(576, $design-width-desktop);
        margin-top: vw(23, $design-width-desktop);
        border-radius: vw(33, $design-width-desktop);
        border: solid vw(3, $design-width-desktop) #999;
      }
    }
  }
}
</style>
